import { GiftIcon } from "@heroicons/react/outline";
import { useApi } from "components/hooks/useApi";
import { useEffect, useState } from "react";
import { ShopSalesOffersAccordion } from "./ShopSalesOffersAccordion";

export const StoreDiscountsList = ({
	storeId,
	productId,
	withInitDiscount = true,
	isDiscountExists = false,
}) => {
	const [initialDiscount, setInitialDiscount] = useState(null);
	const [discountsList, setDiscountsList] = useState([]);
	const [loading, setLoading] = useState(false);
	const api = useApi();

	useEffect(() => {
		if (!storeId || !productId || !isDiscountExists) return;

		const getStoreDiscounts = async () => {
			setLoading(true);
			const res = await api.getStoreDiscounts(storeId, {
				product_id: productId,
			});
			if (res?.ok) {
				const items = res.data.items
					.filter(item => !!item?.title && item?.is_found)
					.map(item => item);
				setDiscountsList(items);
				const initialItem = res.data.items.find(item => !item?.title);
				if (initialItem) {
					setInitialDiscount(initialItem);
				}
			}
			setLoading(false);
		};

		getStoreDiscounts();
	}, [productId]);

	useEffect(() => {
		return () => {
			setDiscountsList([]);
		};
	}, []);

	const isFoundInDiscounts = discountsList.length > 0;
	if (!isDiscountExists) {
		return null;
	}

	if (loading) {
		return (
			<div className=" flex flex-col gap-2 p-2 animate-pulse">
				<div className="h-6 bg-gray-300 rounded"></div>
				<div className="h-4 bg-gray-300 rounded"></div>
				<div className="h-4 bg-gray-300 rounded"></div>
				<div className="h-4 bg-gray-300 rounded"></div>
			</div>
		);
	}

	return (
		isFoundInDiscounts && (
			<div className="w-full border-2 border-dashed px-1 pt-1 pb-2 mt-2">
				<div>
					{discountsList.length > 0 && (
						<div className="flex items-center gap-2">
							<div className="flex items-center">
								<div className="font-bold ml-1 text-red-600 text-sm">
									משתתף במבצע
								</div>
								<GiftIcon className="w-5 h-5 text-red-600 " />
							</div>
							<span className="text-xs">*עד גמר המלאי</span>
						</div>
					)}

					<ShopSalesOffersAccordion
						discountsList={discountsList}
						store={storeId}
					/>
				</div>
			</div>
		)
	);
};
