import { Tooltip } from "react-tippy";
import {
	ChevronDownIcon,
	ChevronUpIcon,
	InformationCircleIcon,
} from "@heroicons/react/outline";
import { Disclosure } from "@headlessui/react";

export function ShopSalesOffersAccordion({ store, discountsList }) {
	if (!store) {
		return null;
	}

	return (
		<div className="text-sm w-full">
			<ol className="list-disc text-gray-500">
				{discountsList.filter(discount => discount.title !== "").length < 1 &&
					"אין מבצעים כרגע"}
				{discountsList
					.filter(discount => discount.title !== "")
					.map((discount, idx) => {
						return (
							<div
								key={idx + discount.title}
								className="flex flex-col items-start"
							>
								<Disclosure>
									{({ open, close }) => (
										<>
											<Disclosure.Button
												className={`py-2 flex justify-between w-full items-center px-2 ${
													idx === 0 ? "border-t" : ""
												}  border-x border-b`}
											>
												<span className="text-right">{discount.title}</span>
												<span>
													{open ? (
														<ChevronUpIcon className="w-3 h-3" />
													) : (
														<ChevronDownIcon className="w-3 h-3" />
													)}
												</span>
											</Disclosure.Button>
											<Disclosure.Panel className="text-gray-500 bg-gray-100 border-x border-b px-3 whitespace-pre-line w-full">
												{discount.description}
											</Disclosure.Panel>
										</>
									)}
								</Disclosure>
							</div>
						);
					})}
			</ol>
		</div>
	);
}