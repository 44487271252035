import { XIcon } from "@heroicons/react/outline";
import { useApi } from "components/hooks/useApi";
import { useIsInIframe } from "components/hooks/useIsInIframe";
import { useRouter } from "next/router";
import React, { Fragment, useEffect, useState } from "react";
import { BsFillInfoCircleFill } from "react-icons/bs";
import { Tooltip } from "react-tippy";

function StrainParentTooltip({
	strainId,
	refToBiz = false,
	tabletMode = false,
	disableLinks = false,
}) {
	const [parents, setParents] = useState([]);
	const isInIframe = useIsInIframe();
	const shouldRefToBiz = isInIframe || refToBiz;
	const api = useApi();
	const router = useRouter();
	const callApi = async () => {
		try {
			const res = await api.getStrainParents(strainId);
			if (res.ok) {
				setParents(res.data);
			} else {
				throw res.error;
			}
		} catch (error) {
			console.error(error.message);
		}
	};
	useEffect(() => {
		void callApi();
	}, []);

	return (
		<>
			{(parents?.first_parents?.length > 0 ||
				parents?.second_parents?.length > 0) && (
				<Tooltip
					html={
						<div className="flex gap-4 items-center">
							{!!parents.first_parents.length > 0 && (
								<div className="text-start">
									<b className="mb-1">הורה #1:</b>
									<ul className="flex gap-1 items-center">
										{parents?.first_parents?.map((parent, index) => (
											<Fragment key={index}>
												{tabletMode || (disableLinks && !isInIframe) ? (
													<li>{parent.heb_name}</li>
												) : parent && shouldRefToBiz ? (
													<li className="underline cursor-pointer">
														{parent.biz_url.length > 0 ? (
															<a
																href={parent.biz_url}
																target="_blank"
																rel="noreferrer noopener"
															>
																{parent.heb_name}
															</a>
														) : (
															parent.heb_name
														)}{" "}
													</li>
												) : (
													<li
														className="underline cursor-pointer"
														onClick={() => router.push("/strains/" + parent.id)}
													>
														{parent.heb_name}
													</li>
												)}
												{parents.first_parents.length - 1 !== index && (
													<XIcon className="w-3 h-3" />
												)}
											</Fragment>
										))}
									</ul>
								</div>
							)}

							{parents.second_parents.length > 0 &&
								parents.first_parents.length > 0 && (
									<div className="h-10 w-[1px] opacity-50 bg-white"></div>
								)}
							{!!parents.second_parents.length > 0 && (
								<div className="text-start">
									<b className="mb-1">הורה #2:</b>
									<ul className="flex gap-1 items-center">
										{parents?.second_parents?.map((parent, index) => (
											<Fragment key={index}>
												{tabletMode || (disableLinks && !isInIframe) ? (
													<li>{parent.heb_name}</li>
												) : parent && shouldRefToBiz ? (
													<li className="underline cursor-pointer">
														{parent.biz_url.length > 0 ? (
															<a
																href={parent.biz_url}
																target="_blank"
																rel="noreferrer noopener"
															>
																{parent.heb_name}
															</a>
														) : (
															parent.heb_name
														)}{" "}
													</li>
												) : (
													<li
														className="underline cursor-pointer"
														onClick={() => router.push("/strains/" + parent.id)}
													>
														{parent.heb_name}
													</li>
												)}

												{parents.second_parents.length - 1 !== index && (
													<XIcon className="w-3 h-3" />
												)}
											</Fragment>
										))}
									</ul>
								</div>
							)}
						</div>
					}
					interactive
					placement="top"
					arrow
					zIndex={0}
				>
					<BsFillInfoCircleFill className="w-4 h-4 text-jane-600 cursor-pointer" />
				</Tooltip>
			)}
		</>
	);
}

export default StrainParentTooltip;
