import React, { useCallback, useEffect, useRef, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination, A11y, Manipulation } from "swiper/modules";
import "swiper/css";
import "swiper/css/navigation";
import { ChevronLeftIcon } from "@heroicons/react/solid";
import Link from "next/link";
import { ProductTypeUI, VARIANT } from "./ProductTypeUI";
import { listProductTypes } from "util/types";
import ProductImage from "components/ui/ProductImage";
import { isComingSoon } from "util/date";

function SimilarProductsSwiper({
	products = [],
	storeId,
	setModalViewProduct,
}) {
	const swiperRef = useRef(null);
	const [isBeginning, setIsBeginning] = useState(true);
	const [isEnd, setIsEnd] = useState(false);
	const isSingleStore = !!storeId;

	const handlePrev = useCallback(() => {
		if (swiperRef.current && swiperRef.current.swiper) {
			swiperRef.current.swiper.slidePrev();
		}
	}, []);

	const handleNext = useCallback(() => {
		if (swiperRef.current && swiperRef.current.swiper) {
			swiperRef.current.swiper.slideNext();
		}
	}, []);

	const handleSlideChange = useCallback(() => {
		if (swiperRef.current && swiperRef.current.swiper) {
			setIsBeginning(swiperRef.current.swiper.isBeginning);
			setIsEnd(swiperRef.current.swiper.isEnd);
		}
	}, []);

	useEffect(() => {
		if (!swiperRef.current) return;
		if (swiperRef.current && swiperRef.current.swiper) {
			const swiper = swiperRef.current.swiper;
			setIsBeginning(swiper.isBeginning);
			setIsEnd(swiper.isEnd);

			const numSlidesVisible = Math.floor(
				swiperRef.current.clientWidth / swiper?.slides[0]?.clientWidth,
			);
			setIsEnd(products.length <= numSlidesVisible);
		}
	}, [products]);

	const handleProductClick = async product => {
		setModalViewProduct(prevState => ({
			isOpen: true,
			history: [...prevState.history, prevState.item],
			item: { ...product, storeId: storeId },
		}));
	};

	return (
		<div className=" relative px-2">
			<Swiper
				ref={swiperRef}
				spaceBetween={16}
				slidesPerView="auto"
				dir="rtl"
				modules={[Navigation, Pagination, A11y, Manipulation]}
				className="!mr-0"
				onSlideChange={handleSlideChange}
			>
				{products.map(product => (
					<SwiperSlide
						key={product.id}
						className="bg-white rounded-md text-center overflow-hidden border border-jane-300 font-semibold !flex flex-col items-center gap-1 !w-fit relative"
					>
						{!isSingleStore &&
							(!product?.store_add_datetime &&
							isComingSoon(product.created_at) ? (
								<ProductCornerBadge color="bg-[#0F85D7]" text="בקרוב" />
							) : !product?.is_in_stock ? (
								<ProductCornerBadge color="bg-gray-500" text="אזל" />
							) : null)}
						{isSingleStore ? (
							<div
								className="px-2 cursor-pointer"
								onClick={() => handleProductClick(product)}
							>
								<ProductView product={product} />
							</div>
						) : (
							<Link href={`/products/${product.id}`}>
								<a target="_blank" rel="noopener noreferrer" className="px-2">
									<ProductView product={product} />
								</a>
							</Link>
						)}
					</SwiperSlide>
				))}
			</Swiper>
			<div
				className={`w-5 h-10 bg-jane-500 rounded-md absolute items-center right-0 top-1/2 transform -translate-y-1/2 cursor-pointer bg-opacity-30 z-10 transition-all hover:bg-opacity-40 ${
					isBeginning ? "hidden" : "flex"
				}`}
				onClick={handlePrev}
			>
				<ChevronLeftIcon className={`text-jane-500 w-5 h-5 rotate-180`} />
			</div>
			<div
				className={`w-5 h-10 bg-jane-500 rounded-md absolute items-center left-0 top-1/2 transform -translate-y-1/2 cursor-pointer bg-opacity-30 z-10 transition-all hover:bg-opacity-40 ${
					isEnd ? "hidden" : "flex"
				}`}
				onClick={handleNext}
			>
				<ChevronLeftIcon className={`text-jane-500 w-5 h-5`} />
			</div>
		</div>
	);
}

export default SimilarProductsSwiper;

function ProductView({ product }) {
	return (
		<>
			<ProductImage
				product={product}
				className="h-20 w-20 rounded-md mx-auto"
			/>
			<div className="flex gap-0.5 justify-center w-full">
				<ProductTypeUI
					productType={listProductTypes.find(productType => {
						return productType.label === product.product_type;
					})}
					variant={VARIANT.SMALLTEXT}
				/>
				<ProductTypeUI
					productType={listProductTypes.find(productType => {
						return productType.label === product.category;
					})}
					variant={VARIANT.SMALLTEXT}
				/>
				<ProductTypeUI
					productType={listProductTypes.find(productType => {
						return productType.label === product.family;
					})}
					variant={VARIANT.SMALLTEXT}
				/>
			</div>
			{product.heb_name}
		</>
	);
}

const ProductCornerBadge = ({ color, text }) => {
	return (
		<span
			className={`${color} w-[120px] text-[12px] text-white text-center absolute rotate-45 z-[11] shadow-md font-semibold bg-opacity-70 origin-top-right top-14 -right-7`}
		>
			{text}
		</span>
	);
};
